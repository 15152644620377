<template>
    <div></div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {};
    },
    created() {},
    methods: {},
    mounted() {
        setTimeout(() => {
            this.$router.push("/");
        }, 300);
    },
};
</script>
<style scoped>
</style>